import Swup from "swup";
import SwupScriptsPlugin from "@swup/scripts-plugin";
import SwupProgressPlugin from "@swup/progress-plugin";
import SwupPreloadPlugin from "@swup/preload-plugin";
import SwupFragmentPlugin from "@swup/fragment-plugin";

export const swup = new Swup({
    containers: ["#swup"],
    plugins: [
        new SwupFragmentPlugin({
            rules: [
                {
                    from: "/projekte/:slug?",
                    to: "/projekte/:slug?",
                    containers: ["#header"],
                },
            ],
        }),
        new SwupScriptsPlugin({
            head: false,
            body: true,
        }),
        new SwupProgressPlugin({
            delay: 500,
        }),
        new SwupPreloadPlugin(),
    ],
});
