import { swup } from "../swup";

export default () => ({
    open: false,

    get isMobile() {
        return !this.$store.breakpoint.sm;
    },

    init() {
        swup.hooks.on("content:replace", () => {
            this.closeMenu();
        });

        this.$watch("isMobile", () => {
            if (!this.isMobile && this.open) {
                this.closeMenu();
            }
        });

        this.$watch("open", async () => {
            if (this.open && this.isMobile) {
                await this.$nextTick();
                this.$refs.mobileNav.scrollTo({ top: 0 });
                document.activeElement.blur();
            }
        });
    },

    toggleMenu() {
        this.open = !this.open;
    },

    closeMenu() {
        this.open = false;
    },
});
