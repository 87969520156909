import "./bootstrap.js";
import "./swup.js";

import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import collapse from "@alpinejs/collapse";

import projectGrid from "./components/projectGrid";
import homeSlider from "./components/homeSlider";
import header from "./components/header";
import surprise from "./components/surprise";
import newsSlider from "./components/newsSlider";
import projectGallery from "./components/projectGallery";
import breakpoint from "./stores/breakpoint";

window.Alpine = Alpine;

Alpine.plugin(collapse);
Alpine.plugin(focus);

document.addEventListener("alpine:init", () => {
    Alpine.data("projectGrid", projectGrid);
    Alpine.data("homeSlider", homeSlider);
    Alpine.data("surprise", surprise);
    Alpine.data("header", header);
    Alpine.data("newsSlider", newsSlider);
    Alpine.data("projectGallery", projectGallery);
    Alpine.store("breakpoint", breakpoint);
});

Alpine.start();
