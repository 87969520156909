import mixitup from "mixitup";
import lazySizes from "lazysizes";
import { swup } from "../swup";
import { match } from "path-to-regexp";

export default () => ({
    mixer: null,
    currentUri: null,

    init() {
        this.currentUri = swup.getCurrentUrl();

        swup.hooks.on("visit:start", (visit) => {
            this.currentUri = visit.to.url;
            this.updateFilter();
        });

        this.initMixer();
        this.updateFilter(false);
    },

    get categorySlug() {
        const matchedCat = match("/projekte/:slug?", {
            decode: decodeURIComponent,
        })(this.currentUri);

        if (matchedCat === false) {
            return null;
        }

        return matchedCat?.params?.slug ?? "alle";
    },

    destroy() {
        if (this.mixer) {
            this.mixer.destroy();
        }
    },

    initMixer() {
        const container = document.querySelector("#mixContainer");

        if (!container || this.mixer) {
            return;
        }

        this.mixer = mixitup(container, {
            selectors: {
                target: ".project",
            },
            animation: {
                clampHeight: false,
                animateResizeContainer: true,
                animateResizeTargets: true,
            },
        });
    },

    async updateFilter(animate = true) {
        if (!this.categorySlug) {
            return;
        }

        await this.mixer.multimix(
            {
                filter: ".cat-" + this.categorySlug,
                changeLayout: "layout-" + this.categorySlug,
            },
            animate,
        );
        lazySizes.autoSizer.checkElems();
        document.activeElement.blur();
        this.fixLayoutClasses();
    },

    fixLayoutClasses() {
        const container = document.querySelector("#mixContainer");

        if (!container) {
            return;
        }

        container.classList.forEach((className) => {
            if (
                className.startsWith("layout-") &&
                !className.startsWith("layout-" + this.categorySlug)
            ) {
                container.classList.remove(className);
            }
        });
    },
});
