import Swiper from "swiper";
import { Keyboard } from "swiper/modules";

export default () => ({
    open: false,
    initialSlide: 0,
    swiper: null,
    // preloaded: false,

    get isMobile() {
        return !this.$store.breakpoint.xs;
    },

    init() {
        this.$watch("open", async (open) => {
            if (open && !this.swiper) {
                this.initSwiper();
                // this.preloadImages();
            } else if (open) {
                this.swiper.slideToLoop(this.initialSlide, 20, false);
            } else if (!open && this.swiper) {
                setTimeout(this.destroySwiper, 500);
            }

            setTimeout(() => {
                document.activeElement.blur();
            }, 100);
        });
    },

    destroy() {
        this.destroySwiper();
    },

    initSwiper() {
        this.swiper = new Swiper(this.$refs.gallerySwiper, {
            modules: [Keyboard],
            loop: true,
            initialSlide: this.initialSlide,
            keyboard: {
                enabled: true,
            },
        });
    },

    openGallery(slide) {
        if (this.isMobile) {
            return;
        }

        this.initialSlide = slide;
        this.open = true;
    },

    destroySwiper() {
        if (this.swiper) {
            this.swiper.destroy();
        }
        this.swiper = null;
    },

    // preloadImages() {
    //     if (this.preloaded) {
    //         return;
    //     }
    //
    //     this.$refs.gallerySwiper
    //         .querySelectorAll('img')
    //         .forEach((el) => el.classList.add('lazypreload'));
    //
    //     this.preloaded = true;
    // },

    prev() {
        if (this.swiper) {
            this.swiper.slidePrev();
        }
    },

    next() {
        if (this.swiper) {
            this.swiper.slideNext();
        }
    },

    navigate(event) {
        // check if mouse position was on the left or right side of the screen
        const x = event.clientX;
        const width = window.innerWidth;
        const isLeft = x < width / 2;

        isLeft ? this.prev() : this.next();
    },
});
